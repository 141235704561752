$primary: #000 !default;

@import "node_modules/bootstrap/scss/bootstrap";

.navbar {
  margin-bottom: 50px;

  .navbar-brand {
    padding: 10px;
  }
}

.container {
  max-width: 1100px;
}

.form-label {
  font-weight: bold;
}

.form-label {
  margin-top: 10px;
}

tr {
  vertical-align: middle;
}
